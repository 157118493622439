import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import './SpeechDisplayer.css';



const SpeechDisplayer = ({ character }) => {
  const canvasRef = useRef(null)
  const ctxref = useRef(null)

  function update(buffer, width, height) {
    if(!canvasRef.current) return
    canvasRef.current.width = width
    canvasRef.current.height = height

    ctxref.current.putImageData(new ImageData(new Uint8ClampedArray(buffer), width, height), 0, 0);
  }




  useEffect(() => {
    ctxref.current = canvasRef.current.getContext('2d')
    if(character) character.registerUpdate(update)

  }, [character])


  return (
    <div className="SpeechDisplayer" >
      <canvas ref={canvasRef} />

    </div>
  );
};

export default observer(SpeechDisplayer)
